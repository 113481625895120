import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import PerDiemFreeDayRules from '@client/_blessed/components/features/Settings/features/PerDiemFreeDayRuless';
import PeoplePage from '../../pages/PeoplePage';
import DepartmentsPage from '../../pages/DepartmentsPage';
import BaseRatePage from '../../pages/BaseRatePage';
import ARAccessorialFeesPage from '../../pages/ARAccessorialFeesPage';
import ARChassisFeesPage from '../../pages/ARChassisFeesPage';
import APChassisFeesPage from '../../pages/APChassisFeesPage';
import APAccessorialFeesPage from '../../pages/APAccessorialFeesPage';
import CarrierRatingRulesPage from '../../pages/CarrierRatingRulesPage';
import PayFormulasPage from '../../_blessed/components/features/PayFormulas/components/PayFormulasForm';
import FuturePricingFormulasPage from '../../_blessed/components/features/PayFormulas/components/FuturePricingForm';

import './style.css';

function SettingsPageLayout(props) {
  useEffect(() => {
    if (props.onToggleLeftSiderbar) {
      props.onToggleLeftSiderbar(true);
    }

    return () => {
      if (props.onToggleLeftSiderbar) {
        props.onToggleLeftSiderbar(false);
      }
    };
  }, []);

  return (
    <Switch>
      <Redirect exact from={props.match.path} to={`${props.match.path}/base-rates`} />
      <Route path={`${props.match.path}/carrier-rating-rules`} component={CarrierRatingRulesPage} />
      <Route path={`${props.match.path}/pay-formulas`} component={PayFormulasPage} />
      <Route path={`${props.match.path}/future-pricing-formulas`} component={FuturePricingFormulasPage} />
      <Route path={`${props.match.path}/ap-accessorial-fees`} component={APAccessorialFeesPage} />
      <Route path={`${props.match.path}/chassis-fees`} component={ARChassisFeesPage} />
      <Route path={`${props.match.path}/base-rates`} component={BaseRatePage} />
      <Route path={`${props.match.path}/per-diem`} component={PerDiemFreeDayRules} />
      <Route path={`${props.match.path}/ar-accessorial-fees`} component={ARAccessorialFeesPage} />
      <Route path={`${props.match.path}/accounts`} component={DepartmentsPage} />
      <Route exact path={`${props.match.path}/people`} component={PeoplePage} />
      <Route exact path={`${props.match.path}/ap-chassis-fees`} component={APChassisFeesPage} />
    </Switch>
  );
}

SettingsPageLayout.propTypes = {
  match: PropTypes.object,
  onToggleLeftSiderbar: PropTypes.func
};

export default React.memo(SettingsPageLayout);
