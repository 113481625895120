import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TrashAltIcon from '@components/deprecatedTookit/icons/fa/regular/TrashAltIcon';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import SystemTooltip from '../../../../components/SystemTooltip';

import './style.css';

const baseClass = 'delete-stop-button';
const className = (name, modifier) => {
  let c = `${baseClass}__${name}`;
  if (modifier) {
    c = `${c} ${c}--${modifier}`;
  }
  return c;
};

function DeleteStopButton(props) {
  const [submitting, setSubmitting] = useState(false);

  const handleClick = async () => {
    if (!props.onDelete || props.disabled) {
      return;
    }

    setSubmitting(true);

    try {
      await props.onDelete();

      if (props.onDeleted) {
        props.onDeleted();
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <span className={baseClass}>
      {!submitting ? (
        <>
          <TrashAltIcon
            onClick={handleClick}
            className={className('icon', props.disabled ? 'disabled' : false)}
            data-tooltip-id="delete-stop-icon"
          />
          <SystemTooltip id="delete-stop-icon" items={[{ message: 'Delete Stop' }]} />
        </>
      ) : (
        <LoadingSpinner className={className('icon', 'spinner')} />
      )}
    </span>
  );
}

DeleteStopButton.propTypes = {
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
  onDeleted: PropTypes.func
};

export default DeleteStopButton;
