import ParkingIcon from '@components/deprecatedTookit/icons/fa/light/ParkingIcon';
import WarehouseIcon from '@components/deprecatedTookit/icons/fa/light/WarehouseIcon';
import ShipIcon from '@components/deprecatedTookit/icons/fa/light/ShipIcon';
import TrainIcon from '@components/deprecatedTookit/icons/fa/regular/TrainIcon';
import ParkingIconSolid from '@components/deprecatedTookit/icons/fa/solid/ParkingIcon';
import WarehouseIconSolid from '@components/deprecatedTookit/icons/fa/solid/WarehouseIcon';
import ShipIconSolid from '@components/deprecatedTookit/icons/fa/solid/ShipIcon';
import TrainIconSolid from '@components/deprecatedTookit/icons/fa/solid/TrainIcon';
import ExclamationSquareIcon from '@components/deprecatedTookit/icons/fa/light/ExclamationSquareIcon';
import SystemTooltip from '../../../../components/SystemTooltip';
import IconButton from '../../../../components/IconButton';

import './style.css';

interface StopProgressBarProps {
  progressNotifications: any[] | null;
  departmentType?: string;
  stopId: string;
  isLastStop?: boolean;
  isFirstOfLeg: boolean;
  isSelected: boolean;
  status: string | null;
  // eslint-disable-next-line no-unused-vars
  onStopClick: ((...args: any) => any) | null;
  locked: boolean;
}

function StopProgressBar(props: StopProgressBarProps) {
  const baseClassName = 'app-stop-progress-bar';
  const className = baseClassName;
  let classNameLineCurrent = `${baseClassName}__progress-line`;
  let icon = null;
  let stopIconClassName = `${baseClassName}__head-icon`;
  const progressLineColorClassName = `${baseClassName}__progress-line--${props.status}`;

  classNameLineCurrent += ` ${progressLineColorClassName}`;
  stopIconClassName += ` ${baseClassName}__head-icon--actionable`;

  if (props.isSelected) {
    stopIconClassName += ` ${baseClassName}__head-icon--selected`;
  }

  const handleStopClick = () => {
    if (!props.locked && props.onStopClick) {
      props.onStopClick();
    }
  };

  const createStopIcon = (Component: any, cProps?: any) => {
    if (cProps && cProps.className) {
      stopIconClassName += ` ${cProps.className}`;
    }

    return <Component className={stopIconClassName} {...cProps} />;
  };

  switch (props.departmentType) {
    case 'terminal':
      // stopTypeDisplayName = 'Terminal';
      if (props.isSelected) {
        icon = createStopIcon(ShipIconSolid);
      } else {
        icon = createStopIcon(ShipIcon);
      }
      break;
    case 'consignee':
      // stopTypeDisplayName = 'Consignee';
      if (props.isSelected) {
        icon = createStopIcon(WarehouseIconSolid);
      } else {
        icon = createStopIcon(WarehouseIcon);
      }
      break;
    case 'rail_yard':
      // stopTypeDisplayName = 'Rail Yard';
      if (props.isSelected) {
        icon = createStopIcon(TrainIconSolid);
      } else {
        icon = createStopIcon(TrainIcon);
      }
      break;
    case 'yard':
      // stopTypeDisplayName = 'Yard';
      if (props.isSelected) {
        icon = createStopIcon(ParkingIconSolid);
      } else {
        icon = createStopIcon(ParkingIcon);
      }
      break;
    case 'container_depot':
      // stopTypeDisplayName = 'Container Depot';
      if (props.isSelected) {
        icon = createStopIcon(ParkingIconSolid);
      } else {
        icon = createStopIcon(ParkingIcon);
      }
      break;
    default:
      // stopTypeDisplayName = 'Error';
      classNameLineCurrent += ` ${baseClassName}__progress-line--error`;
      icon = createStopIcon(ExclamationSquareIcon, {
        className: `${baseClassName}__head-icon--error`
      });
  }

  const firstOfLegKey = props.isFirstOfLeg ? 0 : 1;
  let stopBtnClassName = `${baseClassName}__stop-btn`;

  if (props.isSelected) {
    stopBtnClassName += ` ${baseClassName}__stop-btn--selected`;
  }

  return (
    <div className={className}>
      <div data-tooltip-id={`stop-progress-bar-${props.stopId}-${props.departmentType || 'error'}`}>
        <IconButton
          className={stopBtnClassName}
          variant="outlined"
          icon={icon}
          onClick={handleStopClick}
          disabled={props.locked}
        />
      </div>
      {/* {!props.isSelected && (
        <SystemTooltip
          id={`stop-progress-bar-${props.stopId}-${props.departmentType || 'error'}`}
          items={[{ message: stopTypeDisplayName }]}
        />
      )} */}
      {!props.isLastStop && (
        <div className={classNameLineCurrent} data-tooltip-id={`stop-progress-bar-${props.stopId}-${firstOfLegKey}`} />
      )}
      {props.progressNotifications && (
        <SystemTooltip id={`stop-progress-bar-${props.stopId}-${firstOfLegKey}`} items={props.progressNotifications} />
      )}
    </div>
  );
}

export default StopProgressBar;
