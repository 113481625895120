// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-update-terms-of-service-page {
  display: block;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 30px 30px 0;
  overflow: auto;
}

  .app-update-terms-of-service-page__title {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 15px;
  }

  .app-update-terms-of-service-page__file {
    max-width: 400px;
    padding-top: 15px;
  }

  .app-update-terms-of-service-page__password {
    padding-top: 15px;
    max-width: 400px;
  }

  .app-update-terms-of-service-page__password input {
      appearance: none;
      background: transparent;
      border: 0 white;
      border-bottom: 1px solid #d8d8d8;
      border-radius: 0;
      color: #333;
      display: block;
      padding: 4px;
      outline: 0;
      width: 100%;
    }

  .app-update-terms-of-service-page__password input:focus {
        border-bottom-color: var(--color-green-6);
      }

  .app-update-terms-of-service-page__version {
    font-size: .9em;
    color: #a09f9f;
  }`, "",{"version":3,"sources":["webpack://./app/pages/UpdateTermsOfServicePage/style.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,oBAAoB;EACpB,cAAc;AAuChB;;EArCE;IACE,eAAe;IACf,gBAAgB;IAChB,oBAAoB;EACtB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAkBlB;;EAhBE;MACE,gBAAgB;MAChB,uBAAuB;MACvB,eAAe;MACf,gCAAgC;MAChC,gBAAgB;MAChB,WAAW;MACX,cAAc;MACd,YAAY;MACZ,UAAU;MACV,WAAW;IAKb;;EAHE;QACE,yCAAyC;MAC3C;;EAIJ;IACE,eAAe;IACf,cAAc;EAChB","sourcesContent":[".app-update-terms-of-service-page {\n  display: block;\n  flex-direction: column;\n  flex-grow: 1;\n  height: 100%;\n  padding: 30px 30px 0;\n  overflow: auto;\n\n  &__title {\n    font-size: 16px;\n    font-weight: 500;\n    padding-bottom: 15px;\n  }\n\n  &__file {\n    max-width: 400px;\n    padding-top: 15px;\n  }\n\n  &__password {\n    padding-top: 15px;\n    max-width: 400px;\n\n    input {\n      appearance: none;\n      background: transparent;\n      border: 0 white;\n      border-bottom: 1px solid #d8d8d8;\n      border-radius: 0;\n      color: #333;\n      display: block;\n      padding: 4px;\n      outline: 0;\n      width: 100%;\n\n      &:focus {\n        border-bottom-color: var(--color-green-6);\n      }\n    }\n  }\n\n  &__version {\n    font-size: .9em;\n    color: #a09f9f;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
