import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectDepartmentsById } from '@client/_blessed/store/entities';
import { appContext } from '../../components/App/contexts';
import Component from './component';

function ArApPageConsumer(props) {
  const { state, executeAction } = useContext(appContext);
  const departmentsById = useSelector(selectDepartmentsById);

  const handleToggleLeftSidebar = (open, component) => {
    executeAction((setState) => {
      setState((prevState) => ({
        ...prevState,
        'ui.leftSidebarForArAp.open': open,
        'ui.leftSidebar.open': false,
        'ui.leftSidebar.component': component
      }));
    });
  };

  return (
    <Component
      users={state.users}
      companiesById={state['company.id']}
      departmentsById={departmentsById}
      onToggleLeftSiderbar={handleToggleLeftSidebar}
      {...props}
    />
  );
}

export default ArApPageConsumer;
