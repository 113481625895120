// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dispatch-page-order-leg-stop {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 185px;
  max-width: 185px;
}

  .dispatch-page-order-leg-stop__stop-info {
    margin-top: 10px;
  }
`, "",{"version":3,"sources":["webpack://./app/pages/DispatchPage/components/DispatchPageOrderLegStop/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;AAKlB;;EAHE;IACE,gBAAgB;EAClB","sourcesContent":[".dispatch-page-order-leg-stop {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  min-width: 185px;\n  max-width: 185px;\n\n  &__stop-info {\n    margin-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
