import { useEffect, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { logAnalyticsClick } from '@client/utils/analytics';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { addYardStorage } from '../../../../state/resources/bundle/actions';
import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';
import DialogCard from '../../../../components/DialogCard';
import EditableField from '../../../../components/EditableField';
import { useAppStateContext } from '../../../../components/App/contexts';
import dateTimeRangeDisplayFormatter from '../../../../utils/date-time-range-display-formatter';
import { getDepartmentDisplayName } from '../../../../utils/data-processing/department';
import {
  fetchDeliveryAppointmentOutreachDetails,
  deliveryAppointmentOutreachDetails
} from '../../../../_blessed/components/features/Dispatch/store';
import Stop from '../../../../_blessed/store/entities/stops/model';
import { selectDepartmentsById, selectStopsByLegUUID, selectYards } from '../../../../_blessed/store/entities';

import './style.css';

interface AddStorageYardDialogState {
  addCustomerPrepullFee: boolean;
  addCustomerStorageFee: boolean;
  addCustomerBackhaulStorageFee: boolean;
  addPrepullToMarket: boolean;
  joinBundles: boolean;
  yardStopId: string;
  yardDropoffApptNumber: string | null;
  yardDropoffApptDateStart: string | null;
  yardDropoffApptDateEnd: string | null;
  yardPickupApptNumber: string | null;
  yardPickupApptDateStart: string | null;
  yardPickupApptDateEnd: string | null;
  nextApptNumber: string | null;
  nextApptDateStart: string | null;
  nextApptDateEnd: string | null;
}

interface AddStorageYardDialogProps {
  bundleID: string;
  legID: string;
  stopID: string;
  nextStop: Stop;
  onCancel: () => void;
  onConfirm?: (params: any) => void;
}

function AddStorageYardDialog(props: AddStorageYardDialogProps) {
  const appState = useAppStateContext();
  const dispatch = useAppDispatch();
  const { defaultStopYardStorage, addYardStopBillValues } = useFlags();
  const stopsByLegUUID = useSelector(selectStopsByLegUUID);
  const baseClassName = 'AddStorageYardDialog';
  const className = baseClassName;
  const yards = useSelector(selectYards);
  const departmentsById = useSelector(selectDepartmentsById);
  const stopInstantsById = appState['stopInstant.id'];
  const legStops = stopsByLegUUID[props.legID];
  const splitStop = legStops ? legStops.find((s) => s.uuid === props.stopID) : null;
  const isPrepull = splitStop && splitStop.type === 'terminal';

  const splitStopDepartureStopInstant = splitStop?.departureStopInstant;
  const nextStopArrivalStopInstant = props.nextStop?.arrivalStopInstant;

  const [state, setState] = useState<AddStorageYardDialogState>({
    addCustomerPrepullFee: false,
    addCustomerStorageFee: !!isPrepull,
    addCustomerBackhaulStorageFee: false,
    addPrepullToMarket: splitStopDepartureStopInstant?.appointmentStart !== null,
    joinBundles: false,
    yardStopId: defaultStopYardStorage,
    yardDropoffApptNumber: addYardStopBillValues.autoCutAppointmentNumber,
    yardDropoffApptDateStart: null,
    yardDropoffApptDateEnd: null,
    yardPickupApptNumber: null,
    yardPickupApptDateStart: null,
    yardPickupApptDateEnd: null,
    nextApptNumber: nextStopArrivalStopInstant ? nextStopArrivalStopInstant.appointmentNumber : null,
    nextApptDateStart: nextStopArrivalStopInstant ? nextStopArrivalStopInstant.appointmentStart : null,
    nextApptDateEnd: nextStopArrivalStopInstant ? nextStopArrivalStopInstant.appointmentEnd : null
  });

  const [isConfirming, setIsConfirming] = useState(false);

  const delivery = useSelector(deliveryAppointmentOutreachDetails);

  const dateTimeValueFields = [
    {
      valueField: 'startValue'
    },
    {
      valueField: 'endValue'
    }
  ];
  const nextStopName: string =
    (departmentsById && getDepartmentDisplayName(departmentsById[props.nextStop && props.nextStop.departmentUuid])) ||
    'N/A';
  const nextStopIsTerminal =
    (departmentsById && departmentsById[props.nextStop && props.nextStop.departmentUuid].type === 'terminal') || false;

  const yardDepartmentDropdownOptions = useMemo(
    () =>
      yards.map((yard) => ({
        label: getDepartmentDisplayName(yard),
        id: yard.uuid,
        value: yard.uuid
      })),
    [yards]
  );

  const handleConfirmClick = async () => {
    try {
      logAnalyticsClick('submit add storage', 'dispatch');
      setIsConfirming(true);

      await addYardStorage({
        bundleUuid: props.bundleID,
        stopUuid: props.stopID,
        legUuid: props.legID,
        nextStopInstantUuid: nextStopArrivalStopInstant ? nextStopArrivalStopInstant.uuid : null,
        joinBundles: state.joinBundles,
        addCustomerPrepullFee: state.addCustomerPrepullFee,
        addCustomerStorageFee: state.addCustomerStorageFee,
        addCustomerBackhaulStorageFee: state.addCustomerBackhaulStorageFee,
        addPrepullToMarket: state.addPrepullToMarket,
        yardDepartment: state.yardStopId,
        yardPickAppointment: {
          apptNumber: state.yardPickupApptNumber,
          apptStart: state.yardPickupApptDateStart,
          apptEnd: state.yardPickupApptDateEnd
        },
        yardDropAppointment: {
          apptNumber: state.yardDropoffApptNumber,
          apptStart: state.yardDropoffApptDateStart,
          apptEnd: state.yardDropoffApptDateEnd
        },
        nextAppointment: {
          apptNumber: state.nextApptNumber,
          apptStart: state.nextApptDateStart,
          apptEnd: state.nextApptDateEnd,
          isTerminal: nextStopIsTerminal
        }
      });

      if (props.onConfirm) {
        props.onConfirm(state);
      }

      setIsConfirming(false);
    } catch (err) {
      setIsConfirming(false);
    }
  };

  const handleCancelClick = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleBundleLegsToggle = () => {
    setState((prevState) => ({
      ...prevState,
      joinBundles: !prevState.joinBundles
    }));
  };

  const handleAddPrepullFeeToggle = () => {
    setState((prevState) => ({
      ...prevState,
      addCustomerPrepullFee: !prevState.addCustomerPrepullFee
    }));
  };

  const handleAddStorageFeeToggle = () => {
    setState((prevState) => ({
      ...prevState,
      addCustomerStorageFee: !prevState.addCustomerStorageFee
    }));
  };

  const handleAddBackhaulStorageFeeToggle = () => {
    setState((prevState) => ({
      ...prevState,
      addCustomerBackhaulStorageFee: !prevState.addCustomerBackhaulStorageFee
    }));
  };

  const handleAddToMarketToggle = () => {
    setState((prevState) => ({
      ...prevState,
      addPrepullToMarket: !prevState.addPrepullToMarket
    }));
  };

  const handleYardStopChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      yardStopId: value
    }));
  };

  const handleYardDropoffApptNumberChange = (value: string | null) => {
    setState((prevState) => ({
      ...prevState,
      yardDropoffApptNumber: value
    }));
  };

  const handleYardDropoffApptDateChange = (value: Record<'startValue' | 'endValue', string | null>) => {
    setState((prevState) => ({
      ...prevState,
      yardDropoffApptDateStart: value.startValue,
      yardDropoffApptDateEnd: value.endValue
    }));
  };

  const handleYardPickupApptNumberChange = (value: string | null) => {
    setState((prevState) => ({
      ...prevState,
      yardPickupApptNumber: value
    }));
  };

  const handleYardPickupApptDateChange = (value: Record<'startValue' | 'endValue', string | null>) => {
    setState((prevState) => ({
      ...prevState,
      yardPickupApptDateStart: value.startValue,
      yardPickupApptDateEnd: value.endValue
    }));
  };

  const handleNextApptNumberChange = (value: string | null) => {
    setState((prevState) => ({
      ...prevState,
      nextApptNumber: value
    }));
  };

  const handleNextApptDateChange = (value: Record<'startValue' | 'endValue', string | null>) => {
    setState((prevState) => ({
      ...prevState,
      nextApptDateStart: value.startValue,
      nextApptDateEnd: value.endValue
    }));
  };

  useEffect(() => {
    /**
     * These should already be populated by the time someone gets here,
     *   but just incase check and do nothing
     */
    if (!stopInstantsById || !splitStop || splitStopDepartureStopInstant === null) {
      return;
    }

    if (
      splitStopDepartureStopInstant?.appointmentStart === null ||
      splitStopDepartureStopInstant?.appointmentEnd === null
    ) {
      return;
    }

    /**
     * Make sure these appointments are in the future or else google will error when trying
     *   to generate a time estimate
     */
    if (moment(splitStopDepartureStopInstant!.appointmentStart).isSameOrAfter(moment())) {
      return;
    }

    dispatch(
      fetchDeliveryAppointmentOutreachDetails({
        apptStart: splitStopDepartureStopInstant!.appointmentStart,
        apptEnd: splitStopDepartureStopInstant!.appointmentEnd,
        terminalUUID: splitStop.departmentUuid,
        consigneeUUID: props.nextStop.departmentUuid
      })
    );
  }, []);

  useEffect(() => {
    if (
      splitStopDepartureStopInstant !== null &&
      state.yardDropoffApptDateStart === null &&
      state.yardDropoffApptDateEnd === null &&
      splitStopDepartureStopInstant?.appointmentStart !== null &&
      (delivery.onTimeEarliestArrival !== undefined || delivery.onTimeLatestArrival !== undefined)
    ) {
      setState((prevState) => ({
        ...prevState,
        yardDropoffApptDateStart: delivery.onTimeEarliestArrival!,
        yardDropoffApptDateEnd: delivery.onTimeLatestArrival!
      }));
    }
  }, [delivery]);

  return (
    <DialogCard className={className} onClose={handleCancelClick}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={`${baseClassName}__title-container`}>
          <div className={`${baseClassName}__title-text`}>Add Yard Storage</div>

          <div className={`${baseClassName}__title-sub-text`}>Driver pay will be added automatically.</div>
        </div>

        <div className={`${baseClassName}__content`}>
          <div className={`${baseClassName}__input-section`}>
            <div className={`${baseClassName}__input-section-title`}>Yard Stop</div>
            <div className={`${baseClassName}__input-section-sub-title`}>(Required)</div>
            <div className={`${baseClassName}__input-section-inputs`}>
              <EditableField
                type="dropdown"
                value={state.yardStopId}
                placeholder="Department"
                editable
                inputProps={{
                  dropdownHandle: true,
                  labelField: 'label',
                  valueField: 'id',
                  options: yardDepartmentDropdownOptions
                }}
                displayFormatter={(value: string) => {
                  const defaultYardSelection = yards.find((yard) => yard.uuid === value);

                  return getDepartmentDisplayName(defaultYardSelection) || null;
                }}
                onSave={handleYardStopChange}
              />
            </div>
          </div>

          <div className={`${baseClassName}__input-section`}>
            <div className={`${baseClassName}__input-section-title`}>Yard Drop-off Appt</div>
            <div className={`${baseClassName}__input-section-sub-title`}>(Optional)</div>
            <div className={`${baseClassName}__input-section-inputs`}>
              <EditableField
                editable
                type="text"
                placeholder="Appt #"
                value={state.yardDropoffApptNumber}
                onSave={handleYardDropoffApptNumberChange}
              />
              <EditableField
                type="date-time-range"
                placeholder="Appt"
                editable
                value={{
                  startValue: state.yardDropoffApptDateStart ? state.yardDropoffApptDateStart : null,
                  endValue: state.yardDropoffApptDateEnd ? state.yardDropoffApptDateEnd : null
                }}
                inputProps={{
                  ranged: true
                }}
                valueFields={dateTimeValueFields}
                displayFormatter={dateTimeRangeDisplayFormatter}
                onSave={handleYardDropoffApptDateChange}
              />
            </div>
          </div>

          <div className={`${baseClassName}__input-section`}>
            <div className={`${baseClassName}__input-section-title`}>Yard Pickup Appt</div>
            <div className={`${baseClassName}__input-section-sub-title`}>(Optional)</div>
            <div className={`${baseClassName}__input-section-inputs`}>
              <EditableField type="text" placeholder="Appt #" editable onSave={handleYardPickupApptNumberChange} />
              <EditableField
                type="date-time-range"
                placeholder="Appt"
                editable
                inputProps={{
                  ranged: true
                }}
                valueFields={dateTimeValueFields}
                displayFormatter={dateTimeRangeDisplayFormatter}
                onSave={handleYardPickupApptDateChange}
              />
            </div>
          </div>

          <div className={`${baseClassName}__input-section`}>
            <div className={`${baseClassName}__input-section-title`}>Next Stop</div>
            <div>{nextStopName}</div>
            <div className={`${baseClassName}__input-section-inputs`}>
              <EditableField
                type="text"
                placeholder="Appt #"
                editable
                value={nextStopArrivalStopInstant ? nextStopArrivalStopInstant.appointmentNumber : null}
                onSave={handleNextApptNumberChange}
              />
              <EditableField
                type="date-time-range"
                placeholder="Appt"
                editable
                value={{
                  startValue: nextStopArrivalStopInstant ? nextStopArrivalStopInstant.appointmentStart : null,
                  endValue: nextStopArrivalStopInstant ? nextStopArrivalStopInstant.appointmentEnd : null
                }}
                valueFields={dateTimeValueFields}
                inputProps={{
                  ranged: true
                }}
                displayFormatter={dateTimeRangeDisplayFormatter}
                onSave={handleNextApptDateChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={`${baseClassName}__toggles`}>
        {isPrepull && (
          <Checkbox
            label="Set prepull ready for marketplace"
            checked={state.addPrepullToMarket}
            onToggle={handleAddToMarketToggle}
          />
        )}
        <Checkbox label="Bundle Legs & Sync Carrier Info" onToggle={handleBundleLegsToggle} />
        {isPrepull && (
          <Checkbox
            checked={state.addCustomerPrepullFee}
            label="Add 'Prepull' to Customer Invoice"
            onToggle={handleAddPrepullFeeToggle}
          />
        )}
        {isPrepull ? (
          <Checkbox
            checked={state.addCustomerStorageFee}
            label="Add 'Storage Fee' to Customer Invoice"
            onToggle={handleAddStorageFeeToggle}
          />
        ) : (
          <Checkbox
            checked={state.addCustomerBackhaulStorageFee}
            label="Add 'Backhaul Storage Fee' to Customer Invoice"
            onToggle={handleAddBackhaulStorageFeeToggle}
          />
        )}
      </div>

      <div className={`${baseClassName}__buttons`}>
        <Button label="Cancel" onClick={handleCancelClick} />
        <Button async loading={isConfirming} label="Confirm" theme="2" onClick={handleConfirmClick} />
      </div>
    </DialogCard>
  );
}

export default AddStorageYardDialog;
