import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import request from '../../utils/request';
import detectOS from '../../utils/detectOS';
import Toaster, { raiseToast } from '../../components/Toaster';
import SystemToast from '../../components/SystemToast';
import logoIcon from '../../images/logo_icon.svg';
import { api } from '../../utils/url';

class ResetPasswordPage extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    match: PropTypes.object
  };

  // eslint-disable-next-line react/state-in-constructor
  state = {
    isValidToken: null,
    successfulReset: false,
    password: '',
    repeat: '',
    passwordMismatch: false,
    passwordTooShort: false,
    isLoading: false
  };

  componentDidMount() {
    const { token } = this.props.match.params;
    this.checkToken(token);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    let hasPasswordInputError;
    if (this.state.password.length < 8) {
      this.setState({ passwordTooShort: true });
      hasPasswordInputError = true;
    } else {
      this.setState({ passwordTooShort: false });
    }
    if (this.state.password !== this.state.repeat) {
      this.setState({ passwordMismatch: true });
      hasPasswordInputError = true;
    } else {
      this.setState({ passwordMismatch: false });
    }
    if (hasPasswordInputError) {
      return;
    }

    this.setState({ isLoading: true });
    const { token } = this.props.match.params;
    const payload = {
      meta: {
        action: 'reset_password'
      },
      data: [
        {
          token,
          newPassword: this.state.password
        }
      ]
    };
    const options = {
      method: 'PATCH',
      credentials: 'same-origin',
      body: JSON.stringify(payload)
    };

    try {
      await request(api('/user'), options);
      this.setState({ successfulReset: true });
    } catch (err) {
      this.setState({ isLoading: false });
      raiseToast(
        <SystemToast type={SystemToast.Type.ERROR} message="Unable to reset password. Please request try again." />,
        {
          position: Toaster.Position.TOP_CENTER
        }
      );
    }
  };

  handleInputChange = (event) => {
    const { value } = event.target;
    const { name } = event.target;

    this.setState(() => ({
      [name]: value
    }));
  };

  checkToken = async (token) => {
    const options = {
      method: 'GET',
      credentials: 'same-origin'
    };
    try {
      await request(api(`/user/password-reset/${token}`), options);
      this.setState({ isValidToken: true });
    } catch (err) {
      this.setState({ isValidToken: false });
    }
  };

  render() {
    let component = null;

    if (this.state.successfulReset) {
      let paragraph = (
        <p>
          <a href="drayalliance://Auth">Click here to return to app</a>
        </p>
      );
      const OS = detectOS(navigator, window);

      switch (OS) {
        case 'ios':
          setTimeout(() => {
            window.location = 'drayalliance://Auth';
          }, 6000);
          break;
        case 'android':
          paragraph = (
            <p>
              <a href="drayalliance://Auth">Click here to return to app</a>
            </p>
          );
          setTimeout(() => {
            window.location = 'drayalliance://Auth';
          }, 6000);
          break;
        default:
          paragraph = <p>Please return to the Dray Alliance app and sign in with your new password.</p>;
          break;
      }
      component = (
        <div>
          <h4 className="reset-password-header">Your password has been reset.</h4>
          {paragraph}
        </div>
      );
    } else if (this.state.isValidToken === null) {
      component = (
        <div>
          <h2>LOADING</h2>
        </div>
      );
    } else if (this.state.isValidToken) {
      component = (
        <div>
          <h4 className="reset-password-header">Reset Password</h4>
          <form>
            <div className={this.state.password ? 'reset-form--is-active' : ''}>
              <div className="reset-form__control">
                <label className="reset-form__label" htmlFor="password">
                  New Password
                </label>
                <input
                  id="password"
                  className="reset-form__input"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                  aria-label="Password input"
                />
              </div>
            </div>
            <div className={this.state.repeat ? 'reset-form--is-active' : ''} style={{ marginTop: '8px' }}>
              <div className="reset-form__control">
                <label className="reset-form__label" htmlFor="repeat">
                  Repeat password
                </label>
                <input
                  id="repeat"
                  className="reset-form__input"
                  name="repeat"
                  type="password"
                  value={this.state.repeat}
                  onChange={this.handleInputChange}
                  aria-label="Repeat password input"
                />
              </div>
            </div>
            <div className="password-requirements-text">
              {this.state.passwordTooShort ? (
                <p className="password-error-text">Password must be at least 8 characters.</p>
              ) : (
                <p style={{ margin: 0 }}>Password must be at least 8 characters.</p>
              )}
              {this.state.passwordMismatch && <p className="password-error-text">The passwords do not match.</p>}
            </div>
            <div className="reset-password-form__actions">
              <Button
                className="reset-button"
                loading={this.state.isLoading}
                disabled={!this.state.password || !this.state.repeat}
                type="submit"
                theme="1"
                label="Reset password"
                onClick={this.handleSubmit}
              />
            </div>
            <p>
              Having trouble resetting your password? Contact support at{' '}
              <div style={{ display: 'inline-block' }}>
                <a href="tel:844-767-6776">(844) 767-6776</a>
              </div>
            </p>
            <p>
              Don&apos;t have an account? <a href="/hub/register">Register</a>
            </p>
            <p>
              I have an account. <a href="/hub/login">Sign In</a>
            </p>
          </form>
        </div>
      );
    } else {
      component = (
        <div style={{ marginTop: '20px' }}>
          <p>The link you used is invalid or expired. Please try resetting your password again.</p>
          <p>
            Having trouble resetting your password? Contact support at <br />
            <a href="tel:844-767-6776">(844) 767-6776</a>
          </p>
        </div>
      );
    }

    return (
      <div className="reset-form__window">
        <div className="reset-form__container">
          <div className="reset-form">
            <img src={logoIcon} alt="logo" />
            {component}
          </div>
          <div className="form-image" />
        </div>
      </div>
    );
  }
}

export default ResetPasswordPage;
