// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SplitLegButton__icon {
    height: 15px;
    cursor: pointer;
    margin: 5px 0;
  }

    .SplitLegButton__icon:hover {
      fill: var(--color-green-8);
    }

    .SplitLegButton__icon--disabled {
      fill: var(--color-grayscale-5);
      cursor: default;
    }

    .SplitLegButton__icon--disabled:hover {
        fill: var(--color-grayscale-5);
      }
  .SplitLegButton__actions-flyout {
    width: 170px;
  }
  .SplitLegButton__btn {
    min-width: 15px;
    min-height: 15px;
    width: 15px;
    height: 15px;
    padding: 0;
  }
  .SplitLegButton__btn * svg {
      width: 15px;
      height: 15px;
    }
`, "",{"version":3,"sources":["webpack://./app/pages/DispatchPage/components/SplitLegButton/style.css"],"names":[],"mappings":"AACE;IACE,YAAY;IACZ,eAAe;IACf,aAAa;EAcf;;IAZE;MACE,0BAA0B;IAC5B;;IAEA;MACE,8BAA8B;MAC9B,eAAe;IAKjB;;IAHE;QACE,8BAA8B;MAChC;EAIJ;IACE,YAAY;EACd;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,UAAU;EAMZ;EAJE;MACE,WAAW;MACX,YAAY;IACd","sourcesContent":[".SplitLegButton {\n  &__icon {\n    height: 15px;\n    cursor: pointer;\n    margin: 5px 0;\n\n    &:hover {\n      fill: var(--color-green-8);\n    }\n\n    &--disabled {\n      fill: var(--color-grayscale-5);\n      cursor: default;\n\n      &:hover {\n        fill: var(--color-grayscale-5);\n      }\n    }\n  }\n\n  &__actions-flyout {\n    width: 170px;\n  }\n\n  &__btn {\n    min-width: 15px;\n    min-height: 15px;\n    width: 15px;\n    height: 15px;\n    padding: 0;\n\n    * svg {\n      width: 15px;\n      height: 15px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
