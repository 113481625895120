import React, { ReactElement, useState } from 'react';
import { createStops } from '@client/state/resources/stops/actions';
import { useSelector } from 'react-redux';
import PlusCircleIcon from '@components/deprecatedTookit/icons/fa/regular/PlusCircleIcon';
import { selectLegStopActionsByShorthandMap } from '@client/_blessed/components/features/Dispatch/store';
import DispatchPageOrderLegDetailMenuOption from '@client/pages/DispatchPage/components/DispatchPageOrderLegDetail/components/DispatchPageOrderLegDetailMenuOption';
import * as notifications from '@client/_blessed/store/utils/toastNotifications';
import LoadingSpinner from '@client/components/LoadingSpinner';
import Stop from '@client/_blessed/store/entities/stops/model';
import { BundleLegRelationship } from '@client/state/resources/bundle/models';
import { LegStopActionShortHandEnum } from '@drayalliance/types';
import { logAnalyticsClick } from '@client/utils/analytics';
import DropdownButton from '../../../../components/DropdownButton';
import './styles.css';

interface Props {
  legId: string;
  stop: Stop;
  position: number;
  isFirstLeg: boolean;
  isLastLeg: boolean;
  isFirstStop?: boolean;
  isLastStop?: boolean | undefined;
  disabled?: boolean;
  onExecuteAction: any;
  onStopCreated: any;
  bundleLegRelation: BundleLegRelationship;
}

interface LegData {
  [key: string]: string | number | null | undefined;
}

interface Data {
  type: string;
  attributes?: any;
  relationships: {
    leg: {
      data: LegData[];
    };
  };
}

const baseClass = 'add-stop-button-dropdown';
const className = (name: string, modifier: string): string => {
  let c = `${baseClass}__${name}`;
  if (modifier) {
    c = `${c} ${c}--${modifier}`;
  }
  return c;
};

function AddStopButtonDropdown(props: Props): ReactElement {
  const [submitting, setSubmitting] = useState(false);
  const legStopActionsByShortHand = useSelector(selectLegStopActionsByShorthandMap);

  const isNotBundledBefore = props.bundleLegRelation && !props.bundleLegRelation.previousJoinType;
  const isNotBundleAfter = props.bundleLegRelation && !props.bundleLegRelation.nextJoinType;
  const isFirstStopOfFirstLegAndTerminal =
    props.isFirstLeg && props.isFirstStop && props.stop.type === 'terminal' && isNotBundledBefore;
  const isLastStopOfLastLegAndTerminal =
    props.isLastLeg && props.isLastStop && props.stop.type === 'terminal' && isNotBundleAfter;

  // Determines if stop gets Add Chassis Stop Option
  const showAddChassisStop = !!isFirstStopOfFirstLegAndTerminal || !!isLastStopOfLastLegAndTerminal;

  const handleAddStop = async (legStopActionShortHand?: LegStopActionShortHandEnum): Promise<void> => {
    if (props.disabled) {
      return notifications.sendFailureNotification('Unable to create new stop.');
    }

    const legStopActionUuid =
      legStopActionsByShortHand?.[legStopActionShortHand as unknown as LegStopActionShortHandEnum]?.uuid;

    const data: Data = {
      type: 'stop',
      // sets legStopActionUuid && legStopActionShortHand
      attributes: {
        LegStopRelation: {
          legStopActionUuid,
          legStopActionShortHand
        }
      },
      relationships: {
        leg: {
          data: [
            {
              type: 'leg',
              id: props.legId
            }
          ]
        }
      }
    };

    // Sets stop at first position of leg
    if (isFirstStopOfFirstLegAndTerminal && legStopActionShortHand) {
      data.attributes.LegStopRelation.position = 0;
    } else {
      data.attributes.LegStopRelation.position = props.position + 1;
    }

    setSubmitting(true);

    try {
      if (props.onExecuteAction) {
        const response = await props.onExecuteAction(
          createStops(
            {
              data: [data]
            },
            props.legId
          )
        );

        const stop = response.data && response.data[0];

        if (stop && props.onStopCreated) {
          props.onStopCreated(stop.id, stop.attributes);
        }
      }
    } catch (err) {
      notifications.sendFailureNotification('Unable to add new stop.');
    } finally {
      setSubmitting(false);
    }
  };

  return submitting ? (
    <LoadingSpinner className={className('icon', 'spinner')} />
  ) : (
    <span className={baseClass}>
      <DropdownButton
        buttonProps={{
          transparent: true,
          icon: <PlusCircleIcon className={className('icon', props.disabled ? 'disabled' : '')} />,
          disable: props.disabled,
          className: `${baseClass}__btn`,
          variant: 'text'
        }}
        flyoutProps={{
          direction: 'right',
          floating: true,
          className: `${baseClass}__actions-flyout`
        }}
        className={`${baseClass}__btn`}
      >
        {({ onCloseFlyout }: any): any => {
          const legStopActionShortHand = isFirstStopOfFirstLegAndTerminal
            ? legStopActionsByShortHand[LegStopActionShortHandEnum.PU_C]?.shortHand
            : legStopActionsByShortHand[LegStopActionShortHandEnum.DO_C]?.shortHand;

          return (
            <>
              <DispatchPageOrderLegDetailMenuOption
                label="Add Stop"
                onClick={() => {
                  logAnalyticsClick('add stop', 'dispatch');
                  onCloseFlyout();
                  handleAddStop();
                }}
              />
              {showAddChassisStop && (
                <DispatchPageOrderLegDetailMenuOption
                  async
                  label="Add Chassis Stop"
                  onClick={() => {
                    logAnalyticsClick('add chassis stop', 'dispatch');
                    onCloseFlyout();
                    handleAddStop(legStopActionShortHand);
                  }}
                />
              )}
            </>
          );
        }}
      </DropdownButton>
    </span>
  );
}

export default AddStopButtonDropdown;
