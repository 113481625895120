// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-layout__content {
    display: flex;
    overflow: auto;
    width: 100%;
  }
`, "",{"version":3,"sources":["webpack://./app/layouts/DashboardPageLayout/style.css"],"names":[],"mappings":"AACE;IACE,aAAa;IACb,cAAc;IACd,WAAW;EACb","sourcesContent":[".dashboard-layout {\n  &__content {\n    display: flex;\n    overflow: auto;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
