import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useConsigneeDeliveryPreferences } from '@client/_blessed/store/entities/consigneeDeliveryPreferences/hooks';
import { logAnalyticsClick } from '@client/utils/analytics';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import SplitIcon from '../../../../components/SplitIcon';
import { createSplitLeg } from '../../../../state/resources/orders/actions';
import DropdownButton from '../../../../components/DropdownButton';
import DispatchPageOrderLegDetailMenuOption from '../DispatchPageOrderLegDetail/components/DispatchPageOrderLegDetailMenuOption';
import { toggleAppModal } from '../../../../state/ui/actions';
import { useAppUIActionContext } from '../../../../components/App/contexts';
import AddStorageYardDialog from '../AddStorageYardDialog';
import { fetchLegsByOrderUUIDs } from '../../../../_blessed/components/features/Dispatch/store';

import './style.css';

const baseClassName = 'SplitLegButton';

function SplitLegButton(props) {
  const [isLegSplitting, setLegSplitting] = useState(false);
  const dispatch = useAppDispatch();
  const appUIDispatch = useAppUIActionContext();
  const { apptOutreachEnabled } = useConsigneeDeliveryPreferences({
    consigneeUuid: props.nextStop?.departmentUuid,
    importExport: props.orderImportExport
  });

  const handleAddStorage = async () => {
    logAnalyticsClick('open add storage dialog', 'dispatch');
    appUIDispatch(
      toggleAppModal(
        true,
        <AddStorageYardDialog
          bundleID={props.bundleID}
          orderId={props.orderID}
          legID={props.legID}
          stopID={props.stopID}
          nextStop={props.nextStop}
          onCancel={() => {
            appUIDispatch(toggleAppModal(false));
          }}
          onConfirm={() => {
            appUIDispatch(toggleAppModal(false));

            if (props.onOrderUpdated) {
              props.onOrderUpdated({
                type: 'addYardStorage',
                legId: props.legID,
                stopId: props.stopID,
                nextStop: props.nextStop,
                apptOutreachEnabled
              });
            }
          }}
        />
      )
    );
  };

  const handleSplitLeg = async () => {
    if (props.disabled) {
      return;
    }
    logAnalyticsClick('split leg', 'dispatch');

    const data = {
      orderId: props.orderID,
      legId: props.legID,
      stopIndex: props.currentStopPostion + 1
    };

    setLegSplitting(true);

    try {
      await props.onExecuteAction(createSplitLeg(data.orderId, data.legId, data.stopIndex));
      dispatch(fetchLegsByOrderUUIDs({ orderUUIDs: [props.orderID] }));
      if (props.onLegCreated) {
        props.onLegCreated();
      }
    } finally {
      setLegSplitting(false);
    }
  };

  return (
    <span className={baseClassName}>
      <DropdownButton
        buttonProps={{
          transparent: true,
          icon: <SplitIcon />,
          disabled: props.disabled,
          className: `${baseClassName}__btn`,
          variant: 'text'
        }}
        flyoutProps={{
          direction: 'right',
          floating: true,
          className: `${baseClassName}__actions-flyout`
        }}
        className={`${baseClassName}__btn`}
      >
        {({ onCloseFlyout }) => (
          <>
            <DispatchPageOrderLegDetailMenuOption
              label="Add Storage"
              onClick={() => {
                onCloseFlyout();
                handleAddStorage();
              }}
            />
            <DispatchPageOrderLegDetailMenuOption
              async
              label="Split Leg"
              loading={isLegSplitting}
              onClick={() => {
                onCloseFlyout();
                handleSplitLeg();
              }}
            />
          </>
        )}
      </DropdownButton>
    </span>
  );
}

SplitLegButton.propTypes = {
  currentStopPostion: PropTypes.number.isRequired,
  orderID: PropTypes.string.isRequired,
  legID: PropTypes.string.isRequired,
  stopID: PropTypes.string.isRequired,
  bundleID: PropTypes.string.isRequired,
  nextStop: PropTypes.any,
  disabled: PropTypes.bool,
  onExecuteAction: PropTypes.func,
  onLegCreated: PropTypes.func,
  onOrderUpdated: PropTypes.func,
  orderImportExport: PropTypes.string
};

export default SplitLegButton;
