import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PlusCircleIcon from '@components/deprecatedTookit/icons/fa/regular/PlusCircleIcon';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { createStops } from '../../../../state/resources/stops/actions';
import SystemTooltip from '../../../../components/SystemTooltip';

import './style.css';

const baseClass = 'add-stop-button';
const className = (name, modifier) => {
  let c = `${baseClass}__${name}`;
  if (modifier) {
    c = `${c} ${c}--${modifier}`;
  }
  return c;
};

function AddStopButton(props) {
  const [submitting, setSubmitting] = useState(false);
  const handleClick = async () => {
    if (props.disabled) {
      return;
    }

    const data = {
      type: 'stop',
      relationships: {
        leg: {
          data: [
            {
              type: 'leg',
              id: props.legId
            }
          ]
        }
      }
    };

    if (!props.isLastOfLeg) {
      data.attributes = {
        LegStopRelation: {
          position: props.position + 1
        }
      };
    }

    setSubmitting(true);

    try {
      if (props.onExecuteAction) {
        const response = await props.onExecuteAction(
          createStops(
            {
              data: [data]
            },
            props.legId
          )
        );

        const stop = response.data && response.data[0];

        if (stop && props.onStopCreated) {
          props.onStopCreated(stop.id, stop.attributes);
        }
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <span className={baseClass}>
      {!submitting ? (
        <>
          <PlusCircleIcon
            onClick={handleClick}
            className={className('icon', props.disabled ? 'disabled' : false)}
            data-tooltip-id="add-stop-icon"
          />
          <SystemTooltip id="add-stop-icon" items={[{ message: 'Add Stop' }]} />
        </>
      ) : (
        <LoadingSpinner className={className('icon', 'spinner')} />
      )}
    </span>
  );
}

AddStopButton.propTypes = {
  legId: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  isLastOfLeg: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onExecuteAction: PropTypes.func,
  onStopCreated: PropTypes.func
};

export default AddStopButton;
