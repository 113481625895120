import React from 'react';
import PropTypes from 'prop-types';
import LoaderSpinner from '../../../../../../components/LoadingSpinner';

import './style.css';

function DispatchPageOrderLegDetailMenuOption(props) {
  const baseClassName = 'DispatchPageOrderLegDetailMenuOption';
  let className = baseClassName;

  if (props.className) {
    className += ` ${props.className}`;
  }

  const handleClick = () => {
    if (!props.loading && props.onClick) {
      props.onClick();
    }
  };

  const icon = props.loading ? (
    <LoaderSpinner className={`${baseClassName}__loader`} />
  ) : (
    <div className={`${baseClassName}__loader`} />
  );

  return (
    <button className={className} onClick={handleClick} type="button">
      <span className={`${baseClassName}__label`}>{props.label}</span>
      {props.async && icon}
      {props.children}
    </button>
  );
}

DispatchPageOrderLegDetailMenuOption.propTypes = {
  className: PropTypes.string,
  async: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.any,
  loading: PropTypes.bool,
  onClick: PropTypes.func
};

export default DispatchPageOrderLegDetailMenuOption;
