import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  LegStopAction,
  selectAllLegStopActions
} from '@client/_blessed/components/features/Dispatch/store/legStopActions';
import Stop from '@client/_blessed/store/entities/stops/model';

import EditableField from '@client/components/EditableField';
import SystemTooltip from '@client/components/SystemTooltip/component';

interface Props {
  stop: Stop;
  onFieldChange: any;
  fromBundleSidebar?: boolean;
}

function hashLegStopActions(actions: LegStopAction[]) {
  const map = {} as { [key: string]: LegStopAction };
  actions.forEach((action: LegStopAction) => {
    const { uuid } = action;
    if (map[uuid] === undefined) {
      map[uuid] = action;
    }
  });
  return map;
}

export default function LegStopActionsComponent({ stop, onFieldChange, fromBundleSidebar }: Props): ReactElement {
  const baseClassName = 'legStopActions';

  const [legStopActionType, setLegStopActionType] = useState({} as LegStopAction);

  const legStopActionsStore = useSelector(selectAllLegStopActions);
  const hashedLegStopActions = hashLegStopActions(legStopActionsStore);
  const { lsrUUID, legStopActionUuid } = stop || {};
  const [actualLsrUuid] = lsrUUID ? Object.values(lsrUUID as any) : [];

  useEffect(() => {
    const currentActionForStop = hashedLegStopActions[legStopActionUuid];
    setLegStopActionType(currentActionForStop);
  }, []);

  const handleInputChange = async (value: string, data: any) => {
    const { id, dataResourceType, name } = data;

    // uses handleResourceUpdate from app/pages/DispatchPage/component.tsx to trigger filter
    onFieldChange(value, { id, resourceType: dataResourceType, name });

    const newLegStopAction = hashedLegStopActions[value];
    setLegStopActionType(newLegStopAction);
  };

  /**
   * Todo: Not sure if this is necessary...
   * Refactor LSAUuid to LSAShorthand
   */
  return (
    <>
      <section className={baseClassName} data-tooltip-id={`leg-stop-action-${stop.uuid}-${legStopActionUuid}`}>
        <EditableField
          className={`${baseClassName}__text-input`}
          editable={!fromBundleSidebar}
          id={actualLsrUuid}
          name="legStopActionUuid"
          type="dropdown"
          placeholder={legStopActionType?.display || 'Leg action type'}
          value={legStopActionType?.display}
          inputProps={{
            clearable: true,
            labelField: 'display',
            valueField: 'uuid',
            options: legStopActionsStore || []
          }}
          dataId="legStopActionType"
          dataField="legStopActionType"
          dataResourceType="legStopActions"
          displayFormatter={() => legStopActionType?.display}
          searchBy="display"
          onChange={handleInputChange}
        />
      </section>
      <SystemTooltip
        id={`leg-stop-action-${stop.uuid}-${legStopActionUuid}`}
        items={[{ message: legStopActionType?.display || 'Leg action type' }]}
      />
    </>
  );
}
