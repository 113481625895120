import React, { useContext, useCallback } from 'react';
import { appContext } from '../../components/App/contexts';
import Component from './component';

function SettingsPageConsumer(props) {
  const { executeAction } = useContext(appContext);

  const handleToggleLeftSidebar = useCallback((open, component) => {
    executeAction((setState) => {
      setState((prevState) => ({
        ...prevState,
        'ui.leftSidebar.open': open,
        'ui.leftSidebar.component': component
      }));
    });
  }, []);

  return <Component {...props} onToggleLeftSiderbar={handleToggleLeftSidebar} />;
}

export default SettingsPageConsumer;
