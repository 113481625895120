import { appActionContext } from '@client/components/App/contexts';
import DialogCard from '@client/components/DialogCard';
import { retrieveAttachmentById } from '@client/state/resources/attachments/actions';
import React, { useContext, useEffect, useState } from 'react';
import FileIcon from '@components/deprecatedTookit/icons/fa/regular/FileExportIcon';
import UndoIcon from '@components/deprecatedTookit/icons/fa/regular/UndoIcon';
import getNestedObject from '@client/utils/getNestedObject';
import { retrieveFile } from '@client/state/resources/files/actions';
import { getImageFileSrc } from '@client/utils/data-processing/file';
import moment from 'moment-timezone';
import Button from '@client/components/Button';
import { logAnalyticsClick } from '@client/utils/analytics';
import EditableField from '@client/components/EditableField';
import LoadingScreen from '@components/deprecatedTookit/LoadingScreen';
import dateTimeRangeDisplayFormatter from '@client/utils/date-time-range-display-formatter';
import { TIME_ZONES } from '@drayalliance/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Stop from '../../../../../../_blessed/store/entities/stops/model';

import './style.css';

interface ViewGateTicketModalProps {
  onCancel: any;
  containerNumber?: string;
  attachmentId: string;
  terminalName: string;
  mimeTypes: any;
  gateType: 'ingate' | 'outgate';
  stopInstant: Stop['arrivalStopInstant'] | Stop['departureStopInstant'];
  onConfirm: any;
  timezone?: TIME_ZONES;
}

function dateTimeDisplayFormatter(value: string, showTimezone: boolean) {
  const momentValue = moment(value);

  return value && momentValue.isValid() ? momentValue.format(`MM/DD HH:mm${showTimezone ? ' z' : ''}`) : '';
}

function ViewGateTicketModal(props: ViewGateTicketModalProps) {
  const pageName = 'dispatch/gate ticket modal';
  const { showTimezones } = useFlags();
  const [state, setState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [src, setSrc] = useState('');
  const [actualDateTime, setActualDateTime] = useState(props.stopInstant.actualDateTime);
  const [rotation, setRotation] = useState(0);
  const executeAppAction = useContext(appActionContext);
  const baseClassName = 'ViewGateTicketModal';
  const className = baseClassName;

  const imageRotationStyles = [
    `${baseClassName}-image-rotation0`,
    `${baseClassName}-image-rotation1`,
    `${baseClassName}-image-rotation2`,
    `${baseClassName}-image-rotation3`
  ];

  const attachment = getNestedObject(state, ['attachment.id', props.attachmentId]);
  const fileId = getNestedObject(attachment, ['relationships', 'file', 'data', 'id']);
  const file = getNestedObject(state, ['file.id', fileId]);
  const attachmentCreatedAt = getNestedObject(attachment, ['attributes', 'createdAt']);

  /**
   * When 'showTimezones' flag is enabled, we want to display the uploadedAtMoment in the user's timezone.
   */
  const uploadedAtMoment = showTimezones
    ? moment(attachmentCreatedAt).tz(moment.tz.guess())
    : moment(attachmentCreatedAt).tz(TIME_ZONES.AMERICA_LOS_ANGELES);
  const uploadedAt = uploadedAtMoment.format(`MM/DD/YY HH:mm${showTimezones ? ' z' : ''}`);

  const hasChanges = actualDateTime !== props.stopInstant.actualDateTime;
  const mimeType = file ? props.mimeTypes.lookup(file.attributes.type) : null;

  const appointmentValues = {
    startValue: props.stopInstant.appointmentStart,
    endValue: props.stopInstant.appointmentEnd
  };

  const loadData = async () => {
    await retrieveAttachmentById(props.attachmentId)(setState);
  };

  const loadImage = async () => {
    setIsLoading(true);
    let result = null;
    if (file) {
      result = await executeAppAction(retrieveFile(file.attributes.type, file.id, attachment.attributes.name));
      const fileSrc = await getImageFileSrc(result, mimeType);
      setSrc(fileSrc);
    }
    setIsLoading(false);
  };

  const handleCopy = () => {
    logAnalyticsClick('copy gate time from appointment', pageName);
    setActualDateTime(props.stopInstant.appointmentEnd);
  };

  const handleCancel = () => {
    logAnalyticsClick('cancel', pageName);
    props.onCancel();
  };

  const handleClose = () => {
    logAnalyticsClick('close', pageName);
    props.onCancel();
  };

  const handleSave = async () => {
    logAnalyticsClick('save gate time', pageName);
    props.onConfirm(actualDateTime);
    props.onCancel();
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadImage();
  }, [mimeType]);

  const rotateLeft = () => {
    setRotation(rotation === 0 ? 3 : rotation - 1);
  };

  const rotateRight = () => {
    setRotation(rotation === 3 ? 0 : rotation + 1);
  };

  return (
    <DialogCard className={className} onClose={handleClose}>
      <div className={`${baseClassName}-image-container`}>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            <img className={imageRotationStyles[rotation]} src={src} alt="" />{' '}
            <UndoIcon className={`${baseClassName}-rotate-icon`} onClick={rotateLeft} />
            <UndoIcon
              className={`${baseClassName}-rotate-icon ${baseClassName}-rotate-icon__right`}
              onClick={rotateRight}
            />
          </>
        )}
      </div>

      <div className={`${baseClassName}-detail-bar`}>
        <div className={`${baseClassName}-detail-bar-attachment-info`}>
          <div className={`${baseClassName}-bold-text`}>Gate Ticket</div>
          <div>{props.terminalName}</div>
          <div>{props.containerNumber}</div>
          <div>Uploaded {uploadedAt}</div>
        </div>

        <div className={`${baseClassName}-detail-bar-date-fields`}>
          <div className={`${baseClassName}-detail-bar-column`}>
            <div className={`${baseClassName}-editable-field-label`}>appt time</div>
            <EditableField
              className={`${baseClassName}-editable-field`}
              type="date-time-range"
              displayFormatter={(value: any) =>
                dateTimeRangeDisplayFormatter(value, showTimezones ? props.timezone : undefined)
              }
              value={appointmentValues}
              inputProps={{
                ranged: true
              }}
            />
          </div>
          <FileIcon className={`${baseClassName}-transfer-icon`} onClick={handleCopy} />
          <div className={`${baseClassName}-detail-bar-column`}>
            <div className={`${baseClassName}-editable-field-label`}>{props.gateType} time</div>
            <EditableField
              className={`${baseClassName}-editable-field`}
              type="date-time"
              editable
              displayFormatter={(value: string) => dateTimeDisplayFormatter(value, showTimezones)}
              id={props.stopInstant.uuid}
              name="actualDateTime"
              resourceType="stopInstant"
              value={actualDateTime}
              onChange={(val: string) => setActualDateTime(val)}
              onBlur={() => logAnalyticsClick('manual edit gate date', pageName)}
            />
          </div>
        </div>

        {hasChanges && (
          <div className={`${baseClassName}-action-items`}>
            <Button label="Cancel" onClick={handleCancel} />
            <Button theme="2" label="Confirm" onClick={handleSave} />
          </div>
        )}
      </div>
    </DialogCard>
  );
}

export default ViewGateTicketModal;
