// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-stop-button-dropdown__icon {
    min-width: 15px;
    min-height: 15px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin: 5px 0;
  }

    .add-stop-button-dropdown__icon:hover {
      color: var(--color-green-8);
    }

    .add-stop-button-dropdown__icon__btn {
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: 100%;
    }

    .add-stop-button-dropdown__icon--disabled {
      color: var(--color-grayscale-5);
      cursor: default;
    }

    .add-stop-button-dropdown__icon--disabled:hover {
        color: var(--color-grayscale-5);
      }
  .add-stop-button-dropdown__actions-flyout {
    width: 170px;
  }
`, "",{"version":3,"sources":["webpack://./app/pages/DispatchPage/components/AddStopButtonDropdown/styles.css"],"names":[],"mappings":"AACE;IACE,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,aAAa;EAqBf;;IAnBE;MACE,2BAA2B;IAC7B;;IAEA;MACE,eAAe;MACf,gBAAgB;MAChB,WAAW;MACX,YAAY;IACd;;IAEA;MACE,+BAA+B;MAC/B,eAAe;IAKjB;;IAHE;QACE,+BAA+B;MACjC;EAIJ;IACE,YAAY;EACd","sourcesContent":[".add-stop-button-dropdown {\n  &__icon {\n    min-width: 15px;\n    min-height: 15px;\n    width: 15px;\n    height: 15px;\n    cursor: pointer;\n    margin: 5px 0;\n\n    &:hover {\n      color: var(--color-green-8);\n    }\n\n    &__btn {\n      min-width: 100%;\n      min-height: 100%;\n      width: 100%;\n      height: 100%;\n    }\n\n    &--disabled {\n      color: var(--color-grayscale-5);\n      cursor: default;\n\n      &:hover {\n        color: var(--color-grayscale-5);\n      }\n    }\n  }\n\n  &__actions-flyout {\n    width: 170px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
