import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingScreen from '@components/deprecatedTookit/LoadingScreen';
import EdiCreateOrders from '@client/_blessed/components/features/EdiCreateOrders';
import Header from '../../components/Header';
import DispatchPage from '../../pages/DispatchPage';
import CreateOrdersPage from '../../_blessed/components/features/CreateOrders';
import LiveMapPage from '../../pages/LiveMapPage';
import NotFoundPage from '../../pages/NotFoundPage';
import SettingsPageLayout from '../SettingsPageLayout';
import ArApPageLayout from '../ArApPageLayout';
import RightSidebar from '../../components/RightSidebar';
import LeftSidebar from '../../components/LeftSideBar';
import Admin from '../../_blessed/components/features/Admin';
import TerminalAPI from '../../_blessed/components/features/TerminalAPI';
import Edi204List from '../../_blessed/components/features/Edi';

import './style.css';

function DashboardLayout(props) {
  const { ready } = useTranslation();

  if (!ready) return <LoadingScreen />;

  return (
    <>
      <Header {...props} />
      <div id="dashboard-layout__body">
        <LeftSidebar {...props} />
        <div className="dashboard-layout__content">
          <Switch>
            <Redirect exact from={props.match.path} to={`${props.match.path}/dispatch`} />
            <Route exact path={`${props.match.path}/dispatch`} component={DispatchPage} />
            <Route exact path={`${props.match.path}/create-orders`} component={CreateOrdersPage} />
            <Route exact path={`${props.match.path}/live-map`} component={LiveMapPage} />
            <Route exact path={`${props.match.path}/edi204`} component={Edi204List} />
            <Route exact path={`${props.match.path}/edi204/:transactionId`} component={EdiCreateOrders} />
            <Route path={`${props.match.path}/ar-ap`} component={ArApPageLayout} />
            <Route path={`${props.match.path}/settings`} component={SettingsPageLayout} />
            <Route path={`${props.match.path}/admin`} component={Admin} />
            <Route path={`${props.match.path}/terminal-api`} component={TerminalAPI} />
            <Route component={NotFoundPage} />
          </Switch>
          <RightSidebar {...props} />
        </div>
      </div>
    </>
  );
}

DashboardLayout.propTypes = {
  match: PropTypes.object.isRequired
};

export default DashboardLayout;
