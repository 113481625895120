// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-stop-button__icon {
    height: 15px;
    cursor: pointer;
    margin: 5px 0;
  }

    .add-stop-button__icon:hover {
      color: var(--color-green-8);
    }

    .add-stop-button__icon--disabled {
      color: var(--color-grayscale-5);
      cursor: default;
    }

    .add-stop-button__icon--disabled:hover {
        color: var(--color-grayscale-5);
      }
`, "",{"version":3,"sources":["webpack://./app/pages/DispatchPage/components/AddStopButton/style.css"],"names":[],"mappings":"AACE;IACE,YAAY;IACZ,eAAe;IACf,aAAa;EAcf;;IAZE;MACE,2BAA2B;IAC7B;;IAEA;MACE,+BAA+B;MAC/B,eAAe;IAKjB;;IAHE;QACE,+BAA+B;MACjC","sourcesContent":[".add-stop-button {\n  &__icon {\n    height: 15px;\n    cursor: pointer;\n    margin: 5px 0;\n\n    &:hover {\n      color: var(--color-green-8);\n    }\n\n    &--disabled {\n      color: var(--color-grayscale-5);\n      cursor: default;\n\n      &:hover {\n        color: var(--color-grayscale-5);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
