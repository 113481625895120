// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DispatchPageOrderLegDetailMenuOption {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-height: 37px;
  color: #2a2d30;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

  .DispatchPageOrderLegDetailMenuOption:first-child {
    padding-top: 15px;
  }

  .DispatchPageOrderLegDetailMenuOption:last-child {
    padding-bottom: 15px;
  }

  .DispatchPageOrderLegDetailMenuOption:hover {
    color: white;
    background-color: #2a2d30;
  }

  .DispatchPageOrderLegDetailMenuOption--disabled {
    pointer-events: none;
  }

  .DispatchPageOrderLegDetailMenuOption__label {
    margin-left: 6px;
    margin-right: 4px;
  }

  .DispatchPageOrderLegDetailMenuOption__loader {
    width: 18px;
    height: 18px;
    fill: currentcolor;
  }
`, "",{"version":3,"sources":["webpack://./app/pages/DispatchPage/components/DispatchPageOrderLegDetail/components/DispatchPageOrderLegDetailMenuOption/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,YAAY;AA6Bd;;EA3BE;IACE,iBAAiB;EACnB;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,YAAY;IACZ,yBAAyB;EAC3B;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":[".DispatchPageOrderLegDetailMenuOption {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  max-height: 37px;\n  color: #2a2d30;\n  padding: 10px;\n  font-size: 14px;\n  font-weight: 400;\n  cursor: pointer;\n  width: 100%;\n  height: 100%;\n\n  &:first-child {\n    padding-top: 15px;\n  }\n\n  &:last-child {\n    padding-bottom: 15px;\n  }\n\n  &:hover {\n    color: white;\n    background-color: #2a2d30;\n  }\n\n  &--disabled {\n    pointer-events: none;\n  }\n\n  &__label {\n    margin-left: 6px;\n    margin-right: 4px;\n  }\n\n  &__loader {\n    width: 18px;\n    height: 18px;\n    fill: currentcolor;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
